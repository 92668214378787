import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoadBalancerContainer } from "./components/LoadBalancerContainer";
import { CertsComponent } from "./components/CertsComponent";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoadBalancerContainer />} />
        <Route path="/certs" element={<CertsComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
