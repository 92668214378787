import { KeyboardEvent, useEffect, useState } from "react";
import { Backend, Region, Regions, BackendServer } from "../types/LoadBalancer";
import { ClipLoader } from "react-spinners";
import { Deployments, RegionDeploymentsDict } from "../types/Pods";

export const LoadBalancerContainer = () => {
  const [regionsInfo, setRegionsInfo] = useState<Regions | null>(null);
  const [regionDeployments, setRegionDeployments] = useState<RegionDeploymentsDict>({});
  const [dateUpdated, setDateUpdated] = useState<string | null>(null);
  const [hasError, setHasError] = useState<boolean | null>(null);
  const [dataRegions, setDataRegions] = useState<string[] | null>(null);
  const [dataRegionsInterval, setDataRegionsInterval] = useState<NodeJS.Timeout | null>(null);
  const [dataDeploymentsInterval, setDeploymentsInterval] = useState<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  //get 'regions' params from query string
  const urlParams = new URLSearchParams(window.location.search);
  const regions = urlParams.get("regions");
  var lastDeletePressed: number = 0;

  if (dataRegions === null && regions != null && regions != undefined && regions != "") {
    setDataRegions(regions.split(","));
  }

  function toggleFullScreen() {
    try {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    } catch (error) {
      console.log("Error toggling full screen: ", error);
    }
  }

  const getDeploymentInfos = async (dataRegions: string[] | null) => {
    ["eu", "us"].forEach(async (region) => {
      if (dataRegions != null && dataRegions.length > 0 && !dataRegions.includes(region)) {
        return;
      }

      const response = await fetch("https://dashboard.tradepeg.co.uk/k8s-client/pod-status/" + region);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const deps = regionDeployments;
      deps[region!] = { name: region, deployments: data as Deployments };
      setRegionDeployments(regionDeployments);
    });
  };

  const getLoadBalancerStatus = async (dataRegions: string[] | null) => {
    const _regions = dataRegions != null && dataRegions.length > 0 ? dataRegions.join(",") : null;

    const fetchUrl = _regions === null || _regions === undefined || _regions === "" ? "https://decoder-services.tradepeg-apps.com/service-monitor/load-balancers" : `https://decoder-services.tradepeg-apps.com/service-monitor/load-balancers?regions=${_regions}`;

    setLoading(true);
    const response = await fetch(fetchUrl, {
      headers: new Headers({
        Authorization: "Basic " + btoa("tradepeg:tradepeg"),
      }),
    });
    setLoading(false);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    setDateUpdated(new Date().toLocaleString("en-GB", {}));
    setRegionsInfo(data as Regions);
  };

  const percentToColor = (percent: number | null) => {
    if (percent == null) return "danger";
    if (percent >= 100) return "success";
    else if (percent >= 50) return "warning";
    else return "danger";
  };

  const statusToColor = (status: string) => {
    if (status.startsWith("UP")) {
      return "success";
    } else if (status.startsWith("DOWN")) {
      return "danger";
    } else if (status.startsWith("DRAIN")) {
      return "info";
    }
  };

  const backendService = (backend: Backend, service: BackendServer) => {
    var clsName = "bg-" + statusToColor(service.status);
    if (service.is_backup && !service.is_active) clsName += " backup_server";
    return (
      <div className="server service">
        <div className={clsName} style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
            }}
          >
            <h5>
              {backend.name} / {service.name}
            </h5>
            <h5>{service.session_count.toLocaleString()}</h5>
            <h6>App: [{backend.status}]</h6>
            <h6>Service: {service.status}</h6>
            <h6>{service.last_change}</h6>
            {service.is_backup && <h6>Bck</h6>}
            {service.is_active && service.is_backup && <h6>Act</h6>}
          </div>{" "}
        </div>
      </div>
    );
  };

  const regionBackend = (region: Region, Backend: Backend) => {
    return (
      <div className="app">
        {Backend.services.map((service) => {
          return backendService(Backend, service);
        })}
      </div>
    );
  };

  useEffect(() => {
    const handleKeyDown = (e: globalThis.KeyboardEvent) => {
      const key = e.key;
      switch (key) {
        case "P":
        case "p":
        case "Delete":
          setDataRegions([]);
          if (lastDeletePressed == 0) {
            lastDeletePressed = new Date().getTime();
            toggleFullScreen();
          } else {
            const ago = new Date().getTime() - lastDeletePressed;
            if (ago > 0 && ago <= 1000) {
              setDateUpdated("Reloading... " + ago);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              return;
            } else {
              lastDeletePressed = new Date().getTime();
              toggleFullScreen();
            }
          }
          return;
        case "c":
        case "C":
          setDataRegions(["eu"]);
          return;
        case "v":
        case "V":
          setDataRegions(["us"]);
          return;
        default:
          const pressed = "Key: " + key + " Code: " + e.code;
          setDateUpdated(pressed);
      }
    };

    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    getLoadBalancerStatus(dataRegions);
    getDeploymentInfos(dataRegions);

    if (dataRegionsInterval != null) {
      clearInterval(dataRegionsInterval);
    }

    if (dataDeploymentsInterval != null) {
      clearInterval(dataDeploymentsInterval);
    }

    const regionsInterval = setInterval(async () => {
      setHasError(false);
      try {
        getLoadBalancerStatus(dataRegions);
      } catch (e) {
        setHasError(true);
      }
    }, 10000);

    const deploymentsInterval = setInterval(async () => {
      setHasError(false);
      try {
        getDeploymentInfos(dataRegions);
      } catch (e) {
        setHasError(true);
      }
    }, 5000);

    setDataRegionsInterval(regionsInterval);
    setDeploymentsInterval(deploymentsInterval);

    return () => {
      if (dataRegionsInterval != null) {
        clearInterval(dataRegionsInterval);
      }

      if (dataDeploymentsInterval != null) {
        clearInterval(dataDeploymentsInterval);
      }
    };
  }, [dataRegions]);

  return (
    <div>
      <div id="container">
        {regionsInfo?.map((region) => {
          return (
            <div className="region">
              <h3>{region.name}</h3>
              {region.error && <h2 style={{ color: "red" }}>{region.error}</h2>}
              {region.backends.map((backend) => {
                return regionBackend(region, backend);
              })}

              {regionDeployments && regionDeployments[region.name] != undefined && (
                <div className="app">
                  {regionDeployments[region.name].deployments?.map((deployment) => {
                    return (
                      <div className="server service ">
                        <div className={`bg-${percentToColor(deployment.deployment?.statusInfo?.percent)} grid place-content-center w-full`}>
                          <h3>
                            {deployment.ns} <span className="font-semibold">%{deployment.deployment?.statusInfo?.percent}</span>
                          </h3>
                          <h6>
                            Desired: {deployment?.deployment?.pods?.desired}, Available: {deployment?.deployment?.statusInfo?.available}, Unavailable: {deployment?.deployment?.statusInfo?.unavailable}
                          </h6>

                          {deployment.error && <h2 style={{ color: "red" }}>{deployment.error}</h2>}

                          <div className="w-11/12 bg-gray-200 rounded-full h-3.5 mt-2 m-0 p-0">
                            <div
                              className="bg-green-300 h-3.5 m-0 p-0"
                              style={{
                                width: `${deployment.deployment?.statusInfo?.percent}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div id="now_date" className={hasError ? "bg-red-800" : "bg-white"}>
        {loading && <ClipLoader cssOverride={{ marginRight: "4px" }} color="maroon" size={12} />}
        {dateUpdated}
      </div>
    </div>
  );
};
